import React from "react"
import { Link } from "gatsby"
import { Row, Col, Container, Breadcrumb, Image, Figure } from "react-bootstrap"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import LuteinComplexMobile from "./../../images/hep-drob-main-img.jpg"
import LuteinComplexTablet from "./../../images/hep-drob-main-img.jpg"
import LuteinComplexDesktop from "./../../images/hep-drob-main-img.jpg"
import LuteinComplexXL from "./../../images/hep-drob-main-img.jpg"

import DrNaidenova from "./../../images/hep-drob-dr-naidenova.jpg"
import DrPaskova from "./../../images/hep-drob-dr-paskova.jpg"
import DrSerafiloski from "./../../images/hep-drob-dr-sarafiloski.jpg"

import enzimi from "./../../images/hep-drob-enzimi.jpg"
import liver from "./../../images/hep-drob-fatty-liver.jpg"
import gallBladder from "./../../images/hep-drob-gall-bladder.jpg"
import capsule from "./../../images/hep-drob-caps.png"
import hepatofelin from "./../../images/hepatofelin-article-box.jpg"

const HepatofelinDrob = () => (
  <Layout backButton={true} pageInfo={{ pageName: "hepatofelin-drob" }}>
    <Seo title="Черният дроб не боли — той се срива изведнъж" />
    <Container className="p-0">
      <Row>
        <Col className="d-none d-md-block">
          <Breadcrumb>
            <li className="breadcrumb-item">
              <Link to="/">НАЧАЛНА СТРАНИЦА</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/articles">СТАТИИ</Link>
            </li>
            <Breadcrumb.Item active>
              ЧЕРНИЯТ ДРОБ НЕ БОЛИ — ТОЙ СЕ СРИВА ИЗВЕДНЪЖ
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>

      <Row>
        <Col sm={12}>
          <picture>
            <source srcSet={LuteinComplexXL} media="(min-width: 1200px)" />
            <source srcSet={LuteinComplexDesktop} media="(min-width: 992px)" />
            <source srcSet={LuteinComplexTablet} media="(min-width: 767px)" />
            <source srcSet={LuteinComplexMobile} media="(min-width: 370px)" />
            <img
              srcSet={LuteinComplexMobile}
              alt="Черният дроб не боли — той се срива изведнъж"
              className="w-100 fluid rounded mb-4"
            />
          </picture>
        </Col>
      </Row>

      <Row className="mb-3 product-container">
        <Col sm={12} className="">
          <h1 className="display-5 font-light mb-4 large-heading-mobile">
            Черният дроб не боли — <br />
            той се срива изведнъж
          </h1>
          <h2 className="font-light">
            <em>
              Пътят от завишените чернодробни ензими до тежкия проблем е кратък
            </em>
          </h2>
        </Col>
        <Col sm={12}>
          <p>
            Черният дроб е пречиствателната станция на нашето тяло, която работи
            безропотно по 24 часа в денонощието и то на пълни обороти. Колкото и
            да е натоварен, той не ни се „оплаква". Затова и за проблемите му
            научаваме случайно, а когато ги открием, може и да е късно.
          </p>
          <p>
            Сблъсквали се с не един и два случая на неглижиран проблем, който
            след време е прераснал в сериозно чернодробно заболяване, лекарите
            отдавна знаят, че най-добрата първа помощ, с която нещата отново
            могат да влязат в норма, е природният продукт ХЕПАТОФЕЛИН.
            Обединяващ в себе си най-добрите билкови екстракти за прочистване на
            черния дроб, той дава изключително добри резултати както при леко
            „размърдали се" ензими (АСАТ и АЛАТ), така и при омазнен черен дроб
            и други отежняващи този важен орган състояния. Многократно доказал
            своята ефективност, ХЕПАТОФЕЛИН е мощно натурално оръжие в ръцете на
            специалистите, на което те разчитат и се доверяват безапелационно.
            Той им влиза в употреба и при не малкото случаи на хора с проблеми с
            жлъчката, които цял живот са измъчвани от последиците на лошото
            храносмилане, и подобрява видимо качеството на живот на пациентите.
          </p>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col sm={7}>
          <h3>Откриха ми висок АСАТ и АЛАТ</h3>
          <p>
            „Чернодробните ензими са ти високи" – диагноза, която ни стоварват
            изневиделица. Отишли сме на лекар за едно, той ни е пуснал кръвна
            картина и накрая хоп, оказва се, че са ни сполетели не един, а два
            проблема.
          </p>
          <p>
            Дори когато изглеждат незначителни, не пренебрегвайте отклоненията в
            стойностите на чернодробните ензими. Приемът на ХЕПАТОФЕЛИН е добре
            да започне още при първия сигнал, защото той повлиява по-добрата
            работа на черния дроб и за кратко време може да помогне показателите
            АСАТ и АЛАТ да се възвърнат отново в нормата. А поддържайки всичко в
            препоръчаните граници, черният ни дроб ще ни служи безотказно и то
            за дълго време.
          </p>
        </Col>
        <Col sm={5}>
          <Image className="ml-3 mb-3 float-right w-100" src={enzimi} />
        </Col>
      </Row>

      <Row className="mb-3 product-container">
        <Col>
          <h5>
            <strong>ХЕПАТОФЕЛИН е правилният избор на хепатопротектор</strong>
          </h5>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col sm={4}>
          <Figure className="mr-3 mb-3 w-100 float-left">
            <Figure.Image
              alt="Д-р Тотка Найденова, гастроентеролог, Хирургична клиника &#8222;Матев&#8220;, Стара Загора"
              src={DrNaidenova}
            />
            <Figure.Caption>
              Д-р Тотка Найденова, гастроентеролог, Хирургична клиника „Матев",
              Стара Загора
            </Figure.Caption>
          </Figure>
        </Col>
        <Col sm={8}>
          <p>
            Черният дроб обезврежда всевъзможни вредни фактори, което го
            натоварва и води до бързо увреждане на неговата функционална
            активност. Ето защо съветвам моите пациенти да направят две много
            важни неща: 1. Да водят здравословен начин на живот; 2. Да приемат
            продукти (хранителни добавки) за подпомагане функцията на черния
            дроб – хепатопротектори. Моят професионален избор на хепатопротектор
            се е спрял на природния продукт ХЕПАТОФЕЛИН. Той съдържа различни
            растителни екстракти и естествени вещества, подкрепя функцията на
            черния дроб и съдейства за ускоряване на регенерацията на
            чернодробните клетки. Оптималното съотношение между съставките му –
            силимарин, артишок, глухарче, ленено масло и пречистено соево масло
            (богато на омега-З мастни киселини), както и лесният начин на
            употреба (2 капсули дневно) го правят изключително подходящ за
            подпомагане на редица чернодробни проблеми. Употребата му е показна
            при пациенти, чиито чернодробни показатели се отклоняват от нормата,
            както и при такива, които са в риск от или вече имат по-сериозни
            оплаквания.
          </p>
          <p>
            Предписвам ХЕПАТОФЕЛИН както като самостоятелна терапия, така и в
            комбинация с лекарствени медикаменти. Наблюдавам много добър ефект
            при пациенти от различни възрасти. След едномесечен прием при поне
            половината ми пациентите отчитам тенденция за регулиране стойностите
            на серумните трансаминази (АСАТ и АЛАТ) до таргетните нива.
            ХЕПАТОФЕЛИН е с добър профил на безопасност, не наблюдавам странични
            реакции от приема му.
          </p>
          <p>
            <i>
              Д-р Тотка Найденова, гастроентеролог, Хирургична клиника „Матев",
              Стара Загора
            </i>
          </p>
        </Col>
      </Row>

      <Row className="mb-3 product-container">
        <Col sm={8}>
          <h3>Омазненият черен дроб е първата крачка към цирозата</h3>
          <p>
            Стеатозата е заболяването, което ние, обикновените хора, наричаме
            омазнен черен дроб. Казват, че то поразява основно хората с
            нездравословно хранене, които наблягат на мазните храни, както и
            онези, които си пийват всяка вечер. Не са рядкост обаче и случаите,
            когато с мазни отлагания се оказват дори и най-праволинейните.
            Причините са метаболитни нарушения, траен витаминен недостиг или
            натрупване на токсични вещества в организма. А най-застрашени са
            диабетиците и лицата, които имат проблем с щитовидната жлеза.
          </p>
          <p>
            Добрата новина е, че стеатозата е обратима. Лошата новина е, че ако
            не ѝ обърнем внимание навреме, тя може да прогресира до цироза.
            Именно тук е полезен ХЕПАТОФЕЛИН, чиято специализирана биологична
            формула подпомага успешното очистване на черния дроб от натрупалите
            се мазнини и съдейства за възстановяването на нормалната му дейност.
            Лекарите отдавна са установили, че дори при 3-месечен курс на
            терапия резултатите са много добри, а след 6-месечна терапия може да
            очакваме трайно стабилизиране на състоянието на черния дроб.
          </p>
        </Col>
        <Col sm={4}>
          <Image className="ml-3 mb-3 float-right w-100" src={liver} />
        </Col>
      </Row>

      <Row className="mb-3 product-container">
        <Col>
          <h5>
            <strong>ХЕПАТОФЕЛИН има антистеатозно действие</strong>
          </h5>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col sm={4}>
          <Figure className="mr-3 mb-3 w-100 float-left">
            <Figure.Image
              alt="Д-р Поликсени Паскова, общопрактикуващ лекар, 03 ДКЦ, Варна"
              src={DrPaskova}
            />
            <Figure.Caption>
              Д-р Поликсени Паскова, общопрактикуващ лекар, 03 ДКЦ, Варна
            </Figure.Caption>
          </Figure>
        </Col>
        <Col sm={8}>
          <p>
            Черният дроб обезврежда всевъзможни вредни фактори, което го
            натоварва и води до бързо увреждане на неговата функционална
            активност. Ето защо съветвам моите пациенти да направят две много
            важни неща: 1. Да водят здравословен начин на живот; 2. Да приемат
            продукти (хранителни добавки) за подпомагане функцията на черния
            дроб – хепатопротектори.
          </p>
          <p>
            ХЕПАТОФЕЛИН е първият ми избор на хепатопротектор, поради уникалната
            комбинация от съставки. Силимаринът стимулира регенерацията на
            чернодробния паренхим и задържа процесите, които водят до цироза.
            Той има мощно антиоксидантно действие. Екстрактът от артишок
            редуцира производството на холестерол и стимулира жлъчната секреция.
            Глухарчето от своя страна има хепатопротективно, антиоксидантно и
            антистеатозно действие. Лененото масло е богато на омега-3 и омега-6
            незаменими мастни киселини и витамин F. А соевото масло намалява
            нивата на холестерола и подпомага сърдечно-съдовата система.
          </p>
          <p>
            Предписвам ХЕПАТОФЕЛИН на пациенти с чернодробни увреждания, на
            диабетици, които приемат много медикаменти, и на пациенти,
            злоупотребяващи с алкохол.
          </p>
          <p>
            <i>Д-р Поликсени Паскова, общопрактикуващ лекар, 03 ДКЦ, Варна</i>
          </p>
        </Col>
      </Row>

      <Row className="mb-3 product-container">
        <Col sm={7}>
          <h3>Как жлъчката да заработи отново добре?</h3>
          <p>
            „Боли ме корем" – така сме свикнали да казваме още от деца, когато
            нещо ни присвива отпред, в меката област между гръдния кош и таза.
            Болката там обаче не винаги означава, че имаме язва или пък гастрит.
            Парещото чувство, което се появява след като сме се нахранили, и
            понякога опасва цялото ни тяло е най-разпознаваемият сигнал, че
            жлъчката ни не е в кондиция.
          </p>
          <p>
            Разпространеното мнение е, че главно онези, които злоупотребяват с
            мазните храни, „разяряват" своя жлъчен мехур. Работата е там, че
            неговата „жлъч" понякога се излива и върху напълно невинни,
            „отравяйки" живота им завинаги. Независимо дали сами сме се докарали
            до влошена работа на жлъчката или сме го придобили по наследство,
            със сигурност <Link to="/hepatofelin">ХЕПАТОФЕЛИН</Link> има
            капацитета да ни помогне. Това са забелязали лекари от различни
            специалности, изправени пред задачата да помогнат на своите
            пациенти.
          </p>
        </Col>
        <Col sm={5}>
          <Image className="ml-3 mb-3 float-right w-100" src={gallBladder} />
        </Col>
      </Row>

      <Row className="mb-3 product-container">
        <Col>
          <h5>
            <strong>ХЕПАТОФЕЛИН помага при проблеми с жлъчката</strong>
          </h5>
        </Col>
      </Row>
      <Row className="mb-3 product-container">
        <Col sm={4}>
          <Figure className="mr-3 mb-3 w-100 float-left">
            <Figure.Image
              alt="Д-р Горан Сарафилоски, гастроентеролог, УМБАЛ &#8222;Д-р Георги Странски&#8220;, Плевен"
              src={DrSerafiloski}
            />
            <Figure.Caption>
              Д-р Горан Сарафилоски, гастроентеролог, УМБАЛ „Д-р Георги
              Странски", Плевен
            </Figure.Caption>
          </Figure>
        </Col>
        <Col sm={8}>
          <p>
            ХЕПАТОФЕЛИН има силно детоксикиращо и антиоксидантно действие. За да
            подпомогна подобряването на пациентите си, го изписвам при хора с
            хронични чернодробни заболявания (вирусни хепатити, алкохолен
            хепатит, стеатоза и цироза) и при заболявания на жлъчния мехур и
            жлъчните пътища (възпаления, жлъчно-каменна болест и др.).
            Препоръчаната дневна доза е само 2 капсули, приети след храна.
          </p>
          <p>
            Всички наблюдавани от мен пациенти имат подобрение на състоянието в
            края на тримесечния терапевтичен курс. Подобрява се не само общото
            състояние на пациента, но и неговите чернодробни показатели влизат в
            таргетните стойности.
          </p>
          <p>
            <i>
              Д-р Горан Сарафилоски, гастроентеролог, УМБАЛ „Д-р Георги
              Странски", Плевен
            </i>
          </p>
        </Col>
      </Row>

      <Row className="mb-3 product-container">
        <Col>
          <h3>Иновативната технология за пълноценно усвояване</h3>
          <Image className="ml-3 mb-3 float-right mw-50 " src={capsule} />
          <p>
            <Link to="/hepatofelin">ХЕПАТОФЕЛИН</Link> е произведен във Франция
            по специална технология, при която във всяка капсула се впръскват
            свежи течни съставки. При запечатването на капсулите не се прилага
            термична или химична обработка, поради което съставките са със
            запазена активност и терапевтични качества.
          </p>
          <p>
            Течната формула на ХЕПАТОФЕЛИН гарантира пълноценно усвояване на
            активните съставки, което осигурява по-високата ефективност на
            продукта в сравнение с други, при които съставът е сух или във вид
            на прах например. Благодарение на течното съдържание капсулите могат
            да бъдат приемани спокойно дори и от хора със стомашно-чревни
            проблеми. Приемът на ХЕПАТОФЕЛИН не е обвързан с възрастта, а
            обичайната дневна доза е 2 капсули, приети след храна. Продуктът е
            достъпен в аптеките в цялата страна и е без рецепта.
          </p>
        </Col>
      </Row>

      <Row className="product-container">
        <Col>
          <Link to="/hepatofelin">
            <Image className="w-100" src={hepatofelin} />
          </Link>
        </Col>
      </Row>
      <Row className="product-container">
        <Col>
          <p>Можете да откриете ХЕПАТОФЕЛИН в най-близката до вас аптека.</p>
          <p>
            Телефон за консултация и поръчка:{" "}
            <a href="tel:029446006">02 944 60 06</a>
          </p>
        </Col>
      </Row>
      <Row className="product-container mb-3">
        <Col>
          <h3>
            <Link to="/hepatofelin">
              Повече информация за ХЕПАТОФЕЛИН можете да намерите тук.
            </Link>
          </h3>
        </Col>
      </Row>
      <Row className="product-container mb-3">
        <Col>
          <p>
            *Да не се превишава препоръчваната дневна доза! Продуктът да не се
            използва като заместител на разнообразното хранене!
          </p>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default HepatofelinDrob
